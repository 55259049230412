// @flow

import * as React from 'react';
import { Box, useSystem } from 'react-system';
import { useTheme } from '../theme';

type CssProp = { [string]: mixed, ... } | $ReadOnlyArray<CssProp>;
type NumericProp = number | string | $ReadOnlyArray<number | string>;

type Props = {|
  className?: string,
  children?: React.Node,
  css?: CssProp,
  px?: NumericProp,
|};

export const Center = (props: Props): React.Node => {
  const { media } = useSystem();
  const theme = useTheme();
  return (
    <Box
      className={props.className ?? ''}
      px={props.px ?? [3, 4, 0]}
      width={1}
      mx="auto"
      css={[media({ maxWidth: theme.maxWidths }), props.css]}
    >
      {props.children}
    </Box>
  );
};
