export type Link = {
  href: string;
  label: string;
};

export type Locale =
  | 'en-ch'
  | 'fr-ch'
  | 'de-ch'
  | 'it-ch'
  | 'fr-fr'
  | 'es-es'
  | 'it-it';

export type FooterLinks = Record<
  Locale,
  {
    title: string;
    mainLinks: Link[];
  }[]
>;

export const footerLinks: FooterLinks = {
  'en-ch': [
    {
      title: 'Sell',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/en/online-property-valuation',
          label: 'House valuation',
        },
        {
          href: 'https://realadvisor.ch/en/investment-rental-property-valuation',
          label: 'Investment property valuation',
        },
        {
          href: 'https://realadvisor.ch/en/property-prices',
          label: 'House Prices',
        },
        {
          href: 'https://realadvisor.ch/en/find-agent',
          label: 'Find an agent',
        },
      ],
    },
    {
      title: 'Buy / Rent',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/en/developments',
          label: 'New developments',
        },
        {
          href: 'https://realadvisor.ch/en/financing',
          label: 'Real estate financing',
        },
      ],
    },
    {
      title: 'Professionals',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/en/pro',
          label: 'Become a partner',
        },
      ],
    },
    {
      title: 'About',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/en/about-us',
          label: 'About us',
        },
        {
          href: 'https://realadvisor.ch/en/press-area',
          label: 'Press area',
        },
        {
          href: 'https://realadvisor.welcomekit.co/',
          label: 'Jobs',
        },
        {
          href: 'https://realadvisor.ch/en/terms',
          label: 'Terms and conditions',
        },
        {
          href: 'https://realadvisor.ch/en/privacy',
          label: 'Privacy policy',
        },
        {
          href: 'https://realadvisor.ch/en/legal-information',
          label: 'Legal Information',
        },
        {
          href: 'https://realadvisor.ch/en/sitemap',
          label: 'All real estate',
        },
      ],
    },
  ],
  'fr-ch': [
    {
      title: 'Vendre',
      mainLinks: [
        {
          label: 'Estimation immobilière en ligne',
          href: 'https://realadvisor.ch/fr/estimation-bien-immobilier',
        },
        {
          label: 'Estimation immeuble de rendement',
          href: 'https://realadvisor.ch/fr/estimation-immeuble-de-rendement-gratuite',
        },
        {
          label: 'Prix Immobilier',
          href: 'https://realadvisor.ch/fr/prix-m2-immobilier',
        },
        {
          label: 'Agences immobilières',
          href: 'https://realadvisor.ch/fr/trouver-une-agence',
        },
        {
          label: 'Glossaire Immobilier',
          href: 'https://realadvisor.ch/fr/glossaire-immobilier',
        },
        {
          label: 'Blog Immobilier',
          href: 'https://realadvisor.ch/fr/blog',
        },
      ],
    },
    {
      title: 'Acheter / Louer',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/fr/immobilier-a-vendre',
          label: 'Biens immobiliers à vendre',
        },
        {
          href: 'https://realadvisor.ch/fr/immobilier-a-louer',
          label: 'Biens immobiliers à louer',
        },
        {
          href: 'https://realadvisor.ch/fr/promotions',
          label: 'Promotions immobilières',
        },
        {
          href: 'https://realadvisor-finance.ch',
          label: 'Financement Immobilier',
        },
      ],
    },
    {
      title: 'Professionnels',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/fr/pro',
          label: 'Devenir Partenaire',
        },
        {
          href: 'https://realadvisor.ch/fr/pro/blog',
          label: 'Blog Pro',
        },
      ],
    },
    {
      title: 'À propos',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/fr/a-propos',
          label: 'À propos de nous',
        },
        {
          href: 'https://realadvisor.ch/fr/espace-presse',
          label: 'Espace presse',
        },
        {
          href: 'https://realadvisor.welcomekit.co/',
          label: 'Jobs',
        },
        {
          href: 'https://realadvisor.ch/fr/conditions-generales',
          label: 'Conditions générales',
        },
        {
          href: 'https://realadvisor.ch/fr/politique-de-confidentialite',
          label: 'Politique de confidentialité',
        },
        {
          href: 'https://realadvisor.ch/fr/mentions-legales',
          label: 'Mentions légales',
        },
        {
          href: 'https://realadvisor.ch/fr/sitemap',
          label: "Tout l'immobilier",
        },
      ],
    },
  ],
  'de-ch': [
    {
      title: 'Verkaufen',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/de/online-immobilienbewertung',
          label: 'Immobilienbewertung',
        },
        {
          href: 'https://realadvisor.ch/de/renditeliegenschaft-bewerten-online',
          label: 'Renditeliegenschaft bewerten',
        },
        {
          href: 'https://realadvisor.ch/de/immobilienpreise-pro-m2',
          label: 'Immobilienpreise',
        },
        {
          href: 'https://realadvisor.ch/de/makler-finden',
          label: 'Makler finden',
        },
        {
          href: 'https://realadvisor.ch/de/immobilien-glossar',
          label: 'Immobilien-Glossar',
        },
        {
          href: 'https://realadvisor.ch/de/blog',
          label: 'Immobilien-Blog',
        },
      ],
    },
    {
      title: 'Kaufen / Mieten',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/de/immobilien-kaufen',
          label: 'Immobilien kaufen',
        },
        {
          href: 'https://realadvisor.ch/de/immobilien-mieten',
          label: 'Immobilien mieten',
        },
        {
          href: 'https://realadvisor.ch/de/neubauprojekte',
          label: 'Neubauprojekte',
        },
        {
          href: 'https://realadvisor.ch/de/finanzierung',
          label: 'Immobilienfinanzierung',
        },
      ],
    },
    {
      title: 'Für Makler',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/de/pro',
          label: 'Partner werden',
        },
        {
          href: 'https://realadvisor.ch/de/pro/blog',
          label: 'Blog Pro',
        },
      ],
    },
    {
      title: 'Unternehmen',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/de/uber-uns',
          label: 'Über uns',
        },
        {
          href: 'https://realadvisor.ch/de/presseraum',
          label: 'Presseraum',
        },
        {
          href: 'https://realadvisor.welcomekit.co/',
          label: 'Offene Stellen',
        },
        {
          href: 'https://realadvisor.ch/de/begriffe',
          label: 'Allgemeine Geschäftsbedingungen',
        },
        {
          href: 'https://realadvisor.ch/de/datenschutzerklarung',
          label: 'Datenschutzerklärung',
        },
        {
          href: 'https://realadvisor.ch/de/impressum',
          label: 'Impressum',
        },
        {
          href: 'https://realadvisor.ch/de/sitemap',
          label: 'Alle Immobilien',
        },
      ],
    },
  ],
  'it-ch': [
    {
      title: 'Vendere',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/it/valutazione-immobile-gratuita',
          label: 'Valutazione immobiliare',
        },
        {
          href: 'https://realadvisor.ch/it/valutazione-immobili-a-reddito',
          label: 'Valutazione degli Immobili a reddito',
        },
        {
          href: 'https://realadvisor.ch/it/prezzi-immobili',
          label: 'Prezzo degli immobili',
        },
        {
          href: 'https://realadvisor.ch/it/trova-una-agenzia',
          label: 'Agenzie immobiliari',
        },
        {
          href: 'https://realadvisor.ch/it/blog',
          label: 'Blog immobiliare',
        },
      ],
    },
    {
      title: 'Comprare / Affittare',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/it/vendita-immobile',
          label: 'Immobili in vendita',
        },
        {
          href: 'https://realadvisor.ch/it/immobili-in-affitto',
          label: 'Immobili in affitto',
        },
        {
          href: 'https://realadvisor.ch/it/nuove-costruzioni',
          label: 'Promozioni immobiliari',
        },
        {
          href: 'https://realadvisor.ch/it/finanziamento',
          label: 'Finanziamento immobiliare',
        },
      ],
    },
    {
      title: 'Professionisti',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/it/pro',
          label: 'Diventare partner',
        },
      ],
    },
    {
      title: 'La Società',
      mainLinks: [
        {
          href: 'https://realadvisor.ch/it/chi-siamo',
          label: 'Chi siamo',
        },
        {
          href: 'https://realadvisor.ch/it/press',
          label: 'Press',
        },
        {
          href: 'https://realadvisor.welcomekit.co/',
          label: 'Offerte di lavoro',
        },
        {
          href: 'https://realadvisor.ch/it/termin-e-condizioni',
          label: 'Termini e condizioni',
        },
        {
          href: 'https://realadvisor.ch/it/protezione-dati',
          label: 'Informativa sulla privacy',
        },
        {
          href: 'https://realadvisor.ch/it/informazioni-legali',
          label: 'Informazioni legali',
        },
        {
          href: 'https://realadvisor.ch/it/sitemap',
          label: 'Tutti gli immobili',
        },
      ],
    },
  ],
  'fr-fr': [
    {
      title: 'Vendre',
      mainLinks: [
        {
          href: 'https://realadvisor.fr/fr/estimation-immobiliere-gratuite',
          label: 'Estimation immobilière',
        },
        {
          href: 'https://realadvisor.fr/fr/prix-m2-immobilier',
          label: 'Prix immobilier',
        },
        {
          href: 'https://realadvisor.fr/fr/trouver-une-agence',
          label: 'Agences immobilières',
        },
        {
          href: 'https://realadvisor.fr/fr/glossaire-immobilier',
          label: "Lexique de l'immobilier",
        },
        {
          href: 'https://realadvisor.fr/fr/blog',
          label: 'Blog Immobilier',
        },
      ],
    },
    {
      title: 'Professionnels',
      mainLinks: [
        {
          href: 'https://realadvisor.fr/fr/pro',
          label: 'Devenir partenaire',
        },
        {
          href: 'https://realadvisor.fr/fr/pro/blog',
          label: 'Blog Pro',
        },
      ],
    },
    {
      title: 'À propos',
      mainLinks: [
        {
          href: 'https://realadvisor.fr/fr/a-propos',
          label: 'À propos de nous',
        },
        {
          href: 'https://realadvisor.welcomekit.co/',
          label: 'Jobs',
        },
      ],
    },
    {
      title: 'Informations',
      mainLinks: [
        {
          href: 'https://realadvisor.fr/fr/conditions-generales',
          label: 'Conditions générales',
        },
        {
          href: 'https://realadvisor.fr/fr/politique-confidentialite',
          label: 'Politique de confidentialité',
        },
        {
          href: 'https://realadvisor.fr/fr/cookies',
          label: 'Cookies',
        },
        {
          href: 'https://realadvisor.fr/fr/mentions-legales',
          label: 'Mentions légales',
        },
      ],
    },
  ],
  'es-es': [
    {
      title: 'Servicios',
      mainLinks: [
        {
          href: 'https://realadvisor.es/es/tasacion-vivienda-online',
          label: 'Tasación de Viviendas',
        },
        {
          href: 'https://realadvisor.es/es/precios-viviendas',
          label: 'Precio de viviendas',
        },
        {
          href: 'https://realadvisor.es/es/buscador-agencias-inmobiliarias',
          label: 'Buscador de inmobiliarias',
        },
        {
          href: 'https://realadvisor.es/es/glosario-inmobiliario',
          label: 'Glosario inmobiliario',
        },
        {
          href: 'https://realadvisor.es/es/blog',
          label: 'Blog inmobiliario',
        },
      ],
    },
    {
      title: 'Profesionales',
      mainLinks: [
        {
          href: 'https://realadvisor.es/es/pro',
          label: 'Conviértete en socio',
        },
        {
          href: 'https://realadvisor.es/es/pro/blog',
          label: 'Blog Pro',
        },
      ],
    },
    {
      title: 'Sobre RealAdvisor',
      mainLinks: [
        {
          href: 'https://realadvisor.es/es/pro/sobre-nosotros',
          label: 'Sobre nosotros',
        },
        {
          href: 'https://realadvisor.welcomekit.co/',
          label: 'Trabaja con nosotros',
        },
      ],
    },
    {
      title: 'Información',
      mainLinks: [
        {
          href: 'https://realadvisor.es/es/terminos-y-condiciones',
          label: 'Términos y condiciones',
        },
        {
          href: 'https://realadvisor.es/es/politica-de-privacidad',
          label: 'Política de privacidad',
        },
        {
          href: 'https://realadvisor.es/es/cookies',
          label: 'Política de cookies',
        },
        {
          href: 'https://realadvisor.es/es/aviso-legal',
          label: 'Aviso legal',
        },
      ],
    },
  ],
  'it-it': [
    {
      title: 'Servizi',
      mainLinks: [
        {
          href: 'https://realadvisor.it/it/valutazione-immobile-online-gratuita',
          label: 'Valutazione immobiliare',
        },
        {
          href: 'https://realadvisor.it/it/mercato-immobiliare',
          label: 'Mercato immobiliare',
        },
        {
          href: 'https://realadvisor.it/it/trova-una-agenzia',
          label: 'Agenzie immobiliari',
        },
        {
          href: 'https://realadvisor.it/it/blog',
          label: 'Blog immobiliare',
        },
        {
          href: 'https://realadvisor.it/it/glossario-immobiliare',
          label: 'Glossario immobiliare',
        },
      ],
    },
    {
      title: 'Professionisti',
      mainLinks: [
        {
          href: 'https://realadvisor.it/it/pro',
          label: 'Diventa partner',
        },
        {
          href: 'https://realadvisor.it/it/pro/blog',
          label: 'Blog Pro',
        },
      ],
    },
    {
      title: 'A proposito',
      mainLinks: [
        {
          href: 'https://realadvisor.it/it/chi-siamo',
          label: 'Chi siamo',
        },
        {
          href: 'https://realadvisor.welcomekit.co/',
          label: 'Lavora con noi',
        },
      ],
    },
    {
      title: 'Informazioni',
      mainLinks: [
        {
          href: 'https://realadvisor.it/it/termini-e-condizioni',
          label: 'Termini e Condizioni',
        },
        {
          href: 'https://realadvisor.it/it/privacy',
          label: 'Informativa sulla Privacy',
        },
        {
          href: 'https://realadvisor.it/it/cookies',
          label: 'Cookie',
        },
        {
          href: 'https://realadvisor.it/it/informazioni-legali',
          label: 'Informazioni legali',
        },
      ],
    },
  ],
};
