// @flow

import * as React from 'react';
import { normalLanguages } from '../../language';
import { useRouter } from '../router';
import { StructuredData } from '../structured-data';
import { TrustpilotDe } from './trustpilot-de';
import { TrustpilotEn } from './trustpilot-en';
import { TrustpilotEs } from './trustpilot-es';
import { TrustpilotFr } from './trustpilot-fr';
import { TrustpilotIt } from './trustpilot-it';

const trustpilotLinks = {
  de: 'https://ch.trustpilot.com/review/realadvisor.ch?stars=4&stars=5',
  en: 'https://www.trustpilot.com/review/realadvisor.ch?stars=4&stars=5',
  es: 'https://es.trustpilot.com/review/realadvisor.ch?stars=4&stars=5',
  fr: 'https://fr.trustpilot.com/review/realadvisor.ch?stars=4&stars=5',
  it: 'https://it.trustpilot.com/review/realadvisor.ch?stars=4&stars=5',
};

const ratingImages = {
  de: <TrustpilotDe css={{ maxWidth: '100%', height: 32 }} />,
  en: <TrustpilotEn css={{ maxWidth: '100%', height: 32 }} />,
  es: <TrustpilotEs css={{ maxWidth: '100%', height: 32 }} />,
  fr: <TrustpilotFr css={{ maxWidth: '100%', height: 32 }} />,
  it: <TrustpilotIt css={{ maxWidth: '100%', height: 32 }} />,
};

export const TrustpilotLink = (): React.Node => {
  const router = useRouter();

  const {
    query: { lang },
  } = router;

  const locale = normalLanguages[lang];

  return (
    <>
      <a
        target="_blank"
        href={trustpilotLinks[locale]}
        rel="noreferrer"
        css={{ display: 'inline-block' }}
      >
        {ratingImages[locale]}
      </a>
      {typeof window === 'undefined' ? (
        <StructuredData
          type="Product"
          properties={{
            name: 'RealAdvisor',
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: 4.5,
              reviewCount: 3968,
              bestRating: '5',
            },
          }}
        />
      ) : null}
    </>
  );
};
